import styled from 'styled-components';
import {Modal} from "react-bootstrap";
import {PlayIcon, VideoPlayer} from "../../Content/DailyDashboard/styles";
const RegularFont = 'Rubik-Regular';
const MediumFont = 'Rubik-Medium';
const StyledBody = styled(Modal.Body)`
  padding: 0px;
`;
const StyledModal = styled(Modal)`
  .modal-content{
    border-radius: 12px;
  }
  .modal-backdrop.in{
    opacity: .2;
  }
  .modal-dialog {
    transform: translate(-50%, -50%);
  }
  .info-popup { 
    top: 30%;
    width: 40%;
    margin: 0 auto;
    .modal-header {
      border-bottom: 0px;
      padding: 10px;
    } 
  }
 @media (max-width: 500px){
   .modal-dialog {
    width: 80%;
    }
 }
 @media (min-width: 768px) {
  .modal-content{
    margin-left: ${({height}) => height ? "-150px" : '0px' };
    margin-top: ${({height}) => height ? "-150px" : '0px' };;
  }
}
`;

const ChallengeInfo = styled.div`
  width: 100%;
  padding: 0px 25px 25px;
  > div: first-child {
    color: #67676A;
    font-size: 15px;
  }
  > div{
    font-family: ${RegularFont};
    font-size: 21px;
    color: #3E89C4;
    line-height: 26px;
    text-align: center;
    margin: 8px 0px;
  }
  > div:nth-child(2){
    font-family: ${MediumFont};
    font-size: 25px;
    color: #282C37;
    line-height: 30px;
    text-align: center;
    margin: 8px 0px;
  }
  > div: last-child{
    color: #67676A;
    font-size: 15px;
    opacity: 0.8
    margin-top: 15px;
  }
`;
const PlayIconVideo = styled(PlayIcon)`
  margin-left: 0px;
`;
const VideoPlayerContainer = styled(VideoPlayer)`
  margin-left: 55px;
`;
export { StyledModal, StyledBody, ChallengeInfo, PlayIconVideo, VideoPlayerContainer}
