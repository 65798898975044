import styled from "styled-components";

const MainFitnessScoreCard = styled.div`
    float: left;
    width: 100%;
    display: inline-block;
    font-family: Rubik-Regular;
    margin-bottom: 5px;

`;


const NewFitnessScoreCard = styled.div`
   float: left;
  width: 100%
  display: inline-block;
  margin-bottom: 16px;
  height: 90px;
  .card {
    background-color: #fff;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px; 
    .flex {
      width: 60px;
      height: 60px;
      >img {
        width: 60px;
        height: 60px;
      }
    }
    .block {

    }
  }
 
`;

const NewTotalPoints = styled.div`
width: 398px;
display: flex;
background: #FFFFFF;
border-radius: 6px;
margin-bottom: 20px;
height: 100px;
padding: 10px 0px 10px 10px;
box-shadow: 0px 8px 25px 0px #005C8740;
align-items: center;

>div:first-child {
  width: 60px;
  height: 60px;
  padding: 0px 15px;
  display: flex;
  background-color: #f2f7f9;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  >img{
    width: 60px;
    height: 60px;
  }
}
>div:last-child {
    width: 200%;
    padding: 5px 15px;
  >div:first-child{
    font-family: 'Rubik-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #005c87;
    text-transform: none;
    text-align: left;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: clip;
  }
  >div:last-child{
    font-family: Rubik-Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    text-align: left;
    padding-top: 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
   >span {
    font-size: 14px;
    line-height: 28px;
    font-family: 'Rubik-Regular';
    color: #005c87;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
   } 
  }
 .step_cls {
   color: red;
 }
}
`;

const CountDiv = styled.div`

font-family: Rubik-Medium;
font-style: normal;
font-weight: 500;
font-size: 30px;
line-height: 28px;
color: ${({color}) => color ? 'color' : 'red'}; 
text-align: left;
padding-top: 6px;
>span {
font-size: 14px;
line-height: 28px;
font-family: 'Rubik-Regular';
color: #0D4270;
}
`;



export {MainFitnessScoreCard,NewFitnessScoreCard,NewTotalPoints,CountDiv};