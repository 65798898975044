import styled from "styled-components";
import {SaveButton} from "../../InputGoals/styles";
const MediumFont = 'Rubik-Medium';
const RegularFont = 'Rubik-Regular';
const  SlideShowCard = styled.div`
  width: ${({width}) => width ? 'calc((100% / 3) - 14px)' : '100%'};
  border-radius: 6px;
  display: flex;
  margin: ${({margin}) => margin ? '10px 0' : '10px 7px'};
  position: relative;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 100%;
  float: left;
  .bg-opacity {
    position: absolute;
    height: 200px;
    width: ${({width, featuredWith, margin}) => (width || featuredWith || margin) ? '100%' : 'calc(100% - 10px)'};
    top: 0;
    left: 0;
    background: ${({bgColor}) => bgColor && bgColor};
    opacity: ${({opacity}) => opacity ? opacity : '0.6'};
    border-radius: 6px;
  }
  .information-icon{
    position: absolute;
    top: 15px;
    right: 22px;
    >img{
     width: 26px;
     height: 26px;
    }
  }
  .challenge-user {
    float: left;
    text-transform: capitalize;
    bottom: ${({bottom}) => bottom ? '155px' :  '45px'};
    position: absolute;
    margin-left: 0px;
    left: 33px;
  }
  .user-info {
    display: flex;
    align-items: center;
    margin-left: 0;
    > h6 {
      color: #67676A;
      font-size: 15px
      font-family: ${MediumFont};
      opacity: 0.5
      margin-left: 5px;
      text-transform: none;
      margin-top: 0;
    }
    > span {
      margin-left: -12px;
      > img {
        width:30px;
        height: 30px;
        border-radius: 50%;
        border: 2px solid #fff;
      }
    }
    .user-list {
      color: #FFFFFF;
      font-size: 13px;
      font-family: ${MediumFont};
      opacity: 0.8;
      margin-left: 5px;
      text-transform: none;
      margin-top: 0;
      display: flex;
      justify-content: center;
      align-items: center
    }
   .circle {
    font-family: ${RegularFont};
    font-size: 10px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid #fff;
    text-align: center;
    background-color: #4798D9;
    } 
   }

  @media (max-width: 1235px){
    width: ${({featuredWith, width}) =>  (width || featuredWith)  ? 'calc((100% / 2) - 14px)' : '100%'};
  }
  @media (max-width: 700px){
    width: ${({width, featuredWith}) => (width || featuredWith) ? 'calc((100% / 2) - 14px)' : '100%'};
  }
  @media (max-width: 500px){
    width: ${({width, featuredWith}) => (width || featuredWith) ? 'calc(100% - 14px)' : '100%'};
  }
`;
const ImageContainer = styled.div`
  width: 100%;
  display: inline-block;
  position:relative
  height: ${({height}) => height ? height : '320px'};
  background-color: ${({bgColor}) => bgColor ?  '#FFFFFF' : 'transparent'};
  border-radius: 6px;
  > img {
    cursor: default
    height: 200px;
    border-radius: 6px;
    width: 100%;
    filter: ${({filter}) => filter && 'grayscale(1)'};
  }
`;
const ChallengeTitle = styled.div`
  height: 30%;
  width: 100%;
  padding-top: 7px;
  position: absolute;
  top: 35px;
  left: 25px;
  .title{
    font-size: 16px;
    font-family: ${MediumFont};
    width: 80%;
    text-transform: capitalize;
    overflow: hidden;
    color: #FFFFFF;
    @media (max-width: 500px){
      font-size: 16px;
    }
  }
  .featured{
    font-size: 11px;
    font-family: ${MediumFont};
    width: 80%;
    text-transform: uppercase;
    overflow: hidden;
    color: #FFFFFF;
    opacity: 0.5;
    font-weight: 600;
  }
  .challengeType{
    font-size: 13px;
    font-family: ${MediumFont};
    width: 80%;
    text-transform: uppercase;
    overflow: hidden;
    color: #FFFFFF;
   }
`;
const ChallengeLength = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: ${({bottom}) => bottom ? '5px' : '110px'};
  left: 0;
  padding: 10px 30px 10px 20px;
  .challenge-remaining {
    font-size: 12px;
    font-weight: normal;
    text-transform: capitalize;
    margin-left: 0px;
    color: #FFFFFF;
  }
  .challenge-text {
    text-transform: none;
    margin-left: 0;
    > span {
      color: #1E76AB;
      font-family: ${MediumFont};
    }
  }
  .points{
    font-size: 14px;
    color: #FFFFFF;
    font-family: ${MediumFont};
  }
`;
const AddCalenderButton = styled(SaveButton)`
  margin-top: 20px;
  margin-bottom: 0;
  padding: 0;
  font-size: 12px;
  background-color: #4798D9;
  border: none;
  width: 80%;
  height: 45px;
  font-family: ${RegularFont};
  display: block;
  text-align: center;
  margin: auto;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 1.6px;
  &:focus, &:active, &:hover {
    outline: none;
  }
`;
export {SlideShowCard, ImageContainer, ChallengeTitle, ChallengeLength, AddCalenderButton}