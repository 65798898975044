import styled from "styled-components";
const MainRecommendedCard = styled.div`
   height: 100%;
   display: flex;
   gap: 25px;
   cursor: pointer;
   /* flex-wrap: wrap; */
   /* justify-content: space-between; */
    width: 100%;
    margin-right: 0px;
`;

const RecommendedCardContainer = styled.div`
  /* flex-wrap: wrap; */
  height: 100%;
  cursor: pointer;
  width: 399px;
  border-radius: 6px;
`;

const MainData = styled.div`
width: 100%;
/* flex-wrap: wrap; */


.image_cls {
  width: 100%;
  height: 305px;
  border-radius: 6px;
  box-shadow: 0px 8px 25px 0px #005C8740;
 
   .educateImageBackground {
    position: relative;
    top: -250px;
    width: 100%;
    height: 250px;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px 6px 0px 0px;
    .getStarted {
      font-family: Rubik-Medium;
      font-size: 16px;
      line-height: 15px;
      width: 151px;
      height: 40px;
      background: white;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-radius: 6px;
      color: #005c87;
      box-shadow: 0px 8px 24px 0px #005C8780;
    }

  }

} 

`;

const NewImageContainer = styled.div`
max-width: 100%
height: 250px;
border-radius: 6px 6px 0px 0px;
div{
max-width: 100%;
height: 250px;
/* border-radius: 6px; */
>img{
  width: 100%;
height: 100%;
border-radius: ${({ newBorder }) => (newBorder ? "6px" : " 6px 6px 0px 0px")};
}
  
}
>img{
  width: 100%;
height: 100%;
border-radius: ${({ newBorder }) => (newBorder ? "6px" : " 6px 6px 0px 0px")};

.educateImageBackground {
  width: 100%;
  height: 254px;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px 6px 6px 6px;
 
}`;

const NameDiv = styled.div`
 display:flex;
 justify-content:space-between;
 align-items:center; 
 height:55px !important; 
 padding:0px 15px;
 background: white;
 border-radius: 0px 0px 6px 6px;
 > span {
  color:#005c87;
  font-size:18px;
  font-family: Rubik-medium;
  font-weight: 500;
  line-height: 24px;
 }
 > div {
  height: 24px !important;
 } 
`

const StartButton = styled.div`
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0px;
  width: 151px;
  height: 40px;
  background: linear-gradient(
    90deg,
    rgb(0, 111, 188) -1.11%,
    rgb(127, 67, 255) 97.46%
  );
  color: white;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 6px 6px 6px 6px;
`;

export {
  MainRecommendedCard,
  RecommendedCardContainer,
  StartButton,
  NewImageContainer,
  MainData,
  NameDiv
};
