import React, {Component} from 'react';
import Slider from "react-slick-16";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {ImageContainer, SlideShowCard, ChallengeTitle, ChallengeLength, AddCalenderButton} from './styles';
import ImageThumbnail from "../../ImageThumbnail";
import {ImageUrl, imgPath} from '../../../utils/constants';
import PropTypes from "prop-types";
import {convertDateInTimezone, getChallengeTimeToShow, getComponentName, getStatus, setHexCode} from "../../../utils/methods";
import momentTZ from "moment-timezone";
import moment from "moment";
import {SliderContainer, ChallengeBody, ChallengeSvgContainer} from "../styles";

class ChallengeSlider extends Component{

  challengeImageType = (challenge) => {
    let ComponentName = 'Distance';
    if(challenge.challenge_type != 'behavior') {
      ComponentName = getComponentName(challenge.event_type.split(' ')[1]);
    }
    return <ChallengeSvgContainer width={'15px'}>
      {this.props.categories ? <img src={`${imgPath}/information_icon.png`} /> :
        challenge['event_icon'] ? <img src={`${ImageUrl}/${challenge['event_icon']}`} alt={challenge['event_type']} /> :
          challenge.challenge_type == 'behavior' ?
            <img src={`${imgPath}/behaviorChallenges/${challenge['event_type'] ? challenge['event_type'] : 'Health'}.png`} />
            :
            <ComponentName color={challenge['event_type'] === 'Total Distance' ? '#7cbf41' : '#159fc9'} viewBox="0 0 20 20" marginTop='-6px' />
      }
    </ChallengeSvgContainer>
  };
  challengeLengthPoints = (challenge) => {
    const valid = convertDateInTimezone(challenge.end_date)._d >= moment()._d;
    const time = getChallengeTimeToShow(challenge);
    const DaysLeft = time.map((step) => step);
    return <div className="challenge-remaining">
      {
        <div className="challenge-text">
          {
            (challenge.challenge_status === 'over' || !valid) ?
              <div><span style={{fontSize:'14px'}}>Finished</span></div> :
              <div>
                {
                  <div>
                    <span>{time.map((step, index) => step + (index === (time.length - 1) ? '' : ', '))}</span>
                    {(DaysLeft == 'Starts Today' || DaysLeft == 'Last Day') ? null :(convertDateInTimezone(challenge.start_date)._d <= momentTZ()._d ? ' remaining' : ' left to start')}
                  </div>
                }
              </div>
          }
        </div>
      }
    </div>
  };
  joinButton = (challenge) => {
    let challengeOver = true;
    const isChallengeLocked = getStatus(challenge);
    const valid = convertDateInTimezone(challenge.end_date)._d >= moment()._d;
    const time = getChallengeTimeToShow(challenge);
    const joinOrLeave =  !challengeOver && (challenge.challenge_status === 'joined' ? 0 : 1);
    time.map((step) => {
      const values = step.split(' ');
      if(values && (values[0] === 'Starts' || values[0] === 'Last' || parseInt(values[0],10) > 0)) {
        challengeOver = false;
      }
    });
    return challenge.challenge_status === 'over' || !valid ? null : (challenge.challenge_status === 'running' || challenge.challenge_status === 'leaved') ?
      <AddCalenderButton joinButton onClick={() => this.joinChallenge(challenge.id, joinOrLeave)}>{'Join This Challenge'}</AddCalenderButton>
      : (isChallengeLocked || challenge.challenge_status === 'locked' || time.length === 0) ?  null : (challenge.challenge_status === 'joined') ? null
        : (challenge.challenge_status === 'running' || challenge.challenge_status === 'leaved' || !isChallengeLocked) ? <AddCalenderButton joinButton onClick={() => this.joinChallenge(challenge.id, joinOrLeave)}>{'Join This Challenge'}</AddCalenderButton>
          :  (challenge.challenge_status === 'joined') ? '' : null
  };
  render() {
    const {challenges, history, categories } = this.props;
    let settings = {
      dots: false,
      infinite: challenges.length > 3,
      speed: 300,
      slidesToShow: 3,
      slidesToScroll: challenges.length > 3 ? 3 : challenges.length,
      arrows: challenges.length > 3,
      draggable: false,
      nextArrow: <div className={"left-arrow slick-next"}/>,
      prevArrow: <div className={"left-arrow slick-prev"}/>,
      responsive: [
        {
          breakpoint: 1252,
          settings: {
            dots: false,
            infinite: true,
            speed: 500,
            draggable: false,
            slidesToShow: 2,
            slidesToScroll: 2,
            arrows: true,
          }
        },
        {
          breakpoint: 600,
          settings: {
            dots: false,
            infinite: true,
            speed: 500,
            draggable: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
          }
        },
      ]
    };
    return (
      challenges && challenges.length > 0 ?
        <SliderContainer>
          <Slider {...settings} key={'recommended'}>
            {
              challenges.map((data, index) => (
                <SlideShowCard key={index} opacity={'0.3'} onClick={() => history.push(`/challenges/${data.id}`)} bgColor={categories ? "#000000" : () => setHexCode()}>
                  <ImageContainer height={categories ? '200px' : '320px'} filter={ data['challenge_status'] === 'over' ? 1 : 0}>
                    <ImageThumbnail src={`${ImageUrl}/${data['image']}`} alt={"slide"}/>
                  </ImageContainer>
                  <div className="bg-opacity" />
                  <ChallengeTitle>
                    {this.challengeImageType(data)}
                    <div className="title">
                      {data.title}
                    </div>
                  </ChallengeTitle>
                  <ChallengeLength bottom={categories}>
                    {this.challengeLengthPoints(data)}
                    <div className={"points"}>
                      {`${data['challenge_point'] || 0} pts`}
                    </div>
                  </ChallengeLength>
                  {categories ? null :
                    <ChallengeBody
                      bottom={(data['challenge_status'] === 'joined' || data['challenge_status'] === 'over' || data['challenge_status'] === 'locked') ? 1 : 0}>
                      <div>
                        {data['body']}
                      </div>
                      <div>
                        {this.joinButton(data)}
                      </div>
                    </ChallengeBody>
                  }
                </SlideShowCard>
              ))
            }
          </Slider> </SliderContainer>: null
    );
  }
}
ChallengeSlider.propTypes = {
  history: PropTypes.object,
  challenges: PropTypes.array,
  categories: PropTypes.number
};
export default ChallengeSlider;