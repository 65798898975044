

import React from "react";
import PropTypes from 'prop-types';
import { MainData, MainRecommendedCard, NameDiv, NewImageContainer, RecommendedCardContainer} from "./recommendedStyles";
import { ImageUrl } from "../../../utils/constants";
import Waiting from "../../Waiting";
import isNull from 'lodash/isNull';
import { withTranslation } from 'react-i18next';
import LazyImage from "../../common/LazyImage/LazyImage";
 
class RecommendedChallenge extends React.Component {

  challengeNavigation = (realData) => {
    const{getBehaviorChallengeByIdAction,history, getActivityChallengeByIdAction, manageChallenge} = this.props;
    if(realData.challenge_type==="behavior"){
      history.push(getBehaviorChallengeByIdAction(realData.id, true));
      manageChallenge("behavior")
    }
    else{
      history.push(getActivityChallengeByIdAction(realData.id, true));
      manageChallenge("activity")
    }
  };

  render()
  {
    const  { challengesType, onHover,onEnterMouse ,onLeaveMouse,data, t} = this.props;
    if (isNull(data)) {
      return <Waiting />
    }
    return (
      <MainRecommendedCard>
        {data && data.slice(0,3).map((realData,index) => (
          <RecommendedCardContainer key={index}  onMouseEnter={() => onEnterMouse(realData.id)} onMouseLeave={() => onLeaveMouse()}>
            <MainData>
              <div className="image_cls">
                <NewImageContainer>
                  <LazyImage src={`${ImageUrl}/${realData.image}`} alt="no data"/>
                  <NameDiv>
                    <span>{challengesType === "activity" ? t(realData.title) : t(realData.name)}</span>
                    <div>
                      <LazyImage src={`${ImageUrl}/${realData.event_icon}`} alt="no data" div={"height:24px"} style={{width:"20px", height:"24px"}}/>
                    </div>
                  </NameDiv>
                </NewImageContainer>
                { onHover === realData.id &&
              <div className="educateImageBackground" >
                <div className="getStarted" onClick={() => this.challengeNavigation(realData)}>{t("Launch Now")}</div> 
              </div>
                }
              </div>
              
            </MainData>
          </RecommendedCardContainer>
        ))
        }   
      </MainRecommendedCard>
    )
  }
}
 
RecommendedChallenge.propTypes = {
  onHover: PropTypes.number,
  onEnterMouse: PropTypes.func.isRequired,
  onLeaveMouse: PropTypes.func.isRequired,
  history: PropTypes.object,
  data: PropTypes.array,
  runningStatus: PropTypes.bool,
  challengeNavigation:  PropTypes.func.isRequired,
  getBehaviorChallengeByIdAction: PropTypes.func,
  setUserLiveChallengeStatus: PropTypes.func,
  activity: PropTypes.bool,
  toggleDummyChallenges: PropTypes.func,
  getActivityChallengeByIdAction: PropTypes.func,
  manageChallenge: PropTypes.func,
  t: PropTypes.func,
  challengesType:PropTypes.string
};

export default (withTranslation()(RecommendedChallenge));
